<script setup lang="ts">
import { StructureProps } from '@/interfaces';

const emit = defineEmits<{
  (e: 'dropHandler', id: string): void;
  (e: 'toggleSwitcher', id: string): void;
  (e: 'dropManipulation', id: string): void;
  (e: 'updateStructure', id: string): void;
}>();

const props = defineProps<{
  input: StructureProps['input'];
  data: StructureProps['data'];
  className?: string; 
}>();

const types = {
  selector: props.input.type.includes('selector'),
  plantation: props.input.type === 'plantation',
  recipients: props.input.type === 'recipients',
  accounts: props.input.type === 'accounts',
  password: props.input.type === 'password',
  phone: props.input.type === 'phone',
  drop: props.input.type.includes('drop'),
  qrcode: props.input.type === 'qrcode',
};
const styles = computed(() => {
  return { 'grid-column': props.input.grid, 'grid-row': props.input.grid_row };
});
</script>

<template>
  <lazy-common-structure-selector
    v-if="types.selector && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    @data-manipulation="emit('updateStructure', input.id)"
  ></lazy-common-structure-selector>
  <lazy-common-structure-recipients
    v-else-if="types.recipients && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    @data-manipulation="emit('updateStructure', input.id)"
  ></lazy-common-structure-recipients>
  <lazy-common-structure-plantation
    v-else-if="types.plantation && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    @data-manipulation="emit('updateStructure', input.id)"
  ></lazy-common-structure-plantation>
  <lazy-common-structure-qrcode
    v-else-if="types.qrcode && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    @data-manipulation="emit('updateStructure', input.id)"
  ></lazy-common-structure-qrcode>
  <lazy-common-structure-accounts
    v-else-if="types.accounts && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    @data-manipulation="emit('updateStructure', input.id)"
  ></lazy-common-structure-accounts>
  <lazy-common-structure-password
    v-else-if="types.password && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    :class-name="className"
  ></lazy-common-structure-password>
  <lazy-common-structure-phone
    v-else-if="types.phone && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    :class-name="className"
  ></lazy-common-structure-phone>
  <lazy-common-structure-drop
    v-else-if="types.drop && input.show !== false"
    :input="input"
    :data="data"
    :style="styles"
    @drop-handler="emit('dropHandler', input.id)"
    @drop-manipulation="emit('dropManipulation', input.id)"
  ></lazy-common-structure-drop>
  <lazy-common-structure-default
    v-else-if="input.show !== false"
    :input="input"
    :data="data"
    :class-name="className"
    :style="styles"
    @data-manipulation="emit('updateStructure', input.id)"
  ></lazy-common-structure-default>
</template>

<style scoped lang="scss"></style>
